import { useWeb3React } from "@web3-react/core";
import { TOKENS_MAP } from "config/tokens";
import { useGmxPrice } from "domain/legacy";
import { TokenInfo, useInfoTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { useLLPPrice } from "./useLLPPrice";
import { carouselTokensSortOrder, CarouselTokenSymbols, carouselTokenSymbols } from "./constants";
import { getContract } from "config/contracts";

type UseCarouselTokenReturnType = {
  carouselTokensInfo: TokenInfo[];
  chainId: number;
};

export const useCarouselTokens = (): UseCarouselTokenReturnType => {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();
  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);
  const infoTokensFilteredArr = Object.keys(infoTokens).reduce<TokenInfo[]>((acc, tokenAddress) => {
    const token = infoTokens[tokenAddress];

    if (carouselTokenSymbols[chainId].includes(token.symbol as CarouselTokenSymbols)) {
      acc.push(token);
    }

    return acc;
  }, []);
  const llpAddress = getContract(chainId, "GLP");
  const LLPToken = TOKENS_MAP[chainId][llpAddress];
  const lif3Address = getContract(chainId, "GMX");
  const LIF3Token = TOKENS_MAP[chainId][lif3Address];
  const { glpPrice: llpPrice } = useLLPPrice();
  const { gmxPrice: lif3Price } = useGmxPrice();
  const carouselTokensInfo = [
    ...infoTokensFilteredArr,
    {
      ...LLPToken,
      minPrice: llpPrice,
    },
    {
      ...LIF3Token,
      minPrice: lif3Price,
    },
  ].sort((a, b) => carouselTokensSortOrder[chainId][a.symbol] - carouselTokensSortOrder[chainId][b.symbol]);

  return {
    carouselTokensInfo,
    chainId,
  };
};
