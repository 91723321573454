import axios from "axios";
import { Bar, HistoryMetadata, LibrarySymbolInfo } from "../../../charting_library/datafeed-api";

import { getErrorMessage, RequestParams } from "../helpers";

export interface GetBarsResult {
  bars: Bar[];
  meta: HistoryMetadata;
}

export class HistoryProvider {
  public getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: string,
    rangeStartDate: number,
    rangeEndDate: number,
    chainId: number
  ): Promise<GetBarsResult> {
    const requestParams: RequestParams = {
      symbol: symbolInfo.ticker || "",
      resolution: resolution,
      from: rangeStartDate,
      to: rangeEndDate,
    };

    if (symbolInfo.currency_code !== undefined) {
      requestParams.currencyCode = symbolInfo.currency_code;
    }

    return new Promise((resolve: (result: GetBarsResult) => void, reject: (reason: string) => void) => {
      const period = {
        "5": "5m",
        "15": "15m",
        "60": "1h",
        "240": "4h",
        "1440": "1d",
      };

      axios
        .get<{ prices: { t: number; o: number; c: number; h: number; l: number }[] }>(
          `https://trade-stats.lif3.com/api/candles/${symbolInfo.name}`,
          {
            params: {
              preferableChainId: chainId,
              period: period[resolution],
              from: requestParams.from,
              preferableSource: "fast",
            },
          }
        )
        .then((response) => {
          let meta: HistoryMetadata = {
            noData: false,
          };

          if (requestParams.from < 1550681982) {
            return resolve({
              bars: [],
              meta: {
                noData: true,
              },
            });
          }

          const bars = response.data.prices.map((bar) => ({
            time: bar.t * 1000,
            open: bar.o,
            close: bar.c,
            high: bar.h,
            low: bar.l,
          }));

          if (bars.length === 0) {
            meta = {
              noData: true,
            };
          }

          resolve({
            bars: bars,
            meta: meta,
          });
        })
        .catch((reason?: string | Error) => {
          const reasonString = getErrorMessage(reason);
          // tslint:disable-next-line:no-console
          console.warn(`HistoryProvider: getBars() failed, error=${reasonString}`);
          reject(reasonString);
        });

      // this._requester
      //   .sendRequest<HistoryResponse>(this._datafeedUrl, "history", requestParams)
      //   .then((response: HistoryResponse | UdfErrorResponse) => {
      //     if (response.s !== "ok" && response.s !== "no_data") {
      //       reject(response.errmsg);
      //       return;
      //     }
      //     const bars: Bar[] = [];
      //     const meta: HistoryMetadata = {
      //       noData: false,
      //     };
      //     if (response.s === "no_data") {
      //       meta.noData = true;
      //       meta.nextTime = response.nextTime;
      //     } else {
      //       const volumePresent = response.v !== undefined;
      //       const ohlPresent = response.o !== undefined;
      //       for (let i = 0; i < response.t.length; ++i) {
      //         const barValue: Bar = {
      //           time: response.t[i] * 1000,
      //           close: parseFloat(response.c[i]),
      //           open: parseFloat(response.c[i]),
      //           high: parseFloat(response.c[i]),
      //           low: parseFloat(response.c[i]),
      //         };
      //         if (ohlPresent) {
      //           barValue.open = parseFloat((response as HistoryFullDataResponse).o[i]);
      //           barValue.high = parseFloat((response as HistoryFullDataResponse).h[i]);
      //           barValue.low = parseFloat((response as HistoryFullDataResponse).l[i]);
      //         }
      //         if (volumePresent) {
      //           barValue.volume = parseFloat((response as HistoryFullDataResponse).v[i]);
      //         }
      //         bars.push(barValue);
      //       }
      //     }
      //     resolve({
      //       bars: bars,
      //       meta: meta,
      //     });
      //   })
      //   .catch((reason?: string | Error) => {
      //     const reasonString = getErrorMessage(reason);
      //     // tslint:disable-next-line:no-console
      //     console.warn(`HistoryProvider: getBars() failed, error=${reasonString}`);
      //     reject(reasonString);
      //   });
    });
  }
}
