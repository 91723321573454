import { useWeb3React } from "@web3-react/core";
import { DEFAULT_CHAIN_ID, isSupportedChain } from "config/chains";
import { useLocation } from "react-router";
import { useMemo } from "react";

export function useChainId() {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const URLChainId = searchParams.get("chainId");
  const { chainId: userChainId } = useWeb3React();

  const resultChainId = URLChainId ? Number(URLChainId) : userChainId;

  return isSupportedChain(resultChainId) ? { chainId: resultChainId! } : { chainId: DEFAULT_CHAIN_ID };
}
