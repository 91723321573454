import { Trans, t } from "@lingui/macro";

function InfoCard({ label, data }) {
  return (
    <div className="App-card referrals-stats-card">
      <div className="App-card-title">{label}</div>
      <div className="App-card-divider" />
      <div className="App-card-content">
        <Trans>{data}</Trans>
      </div>
    </div>
  );
}

export default InfoCard;
