import { ReactNode } from "react";
import connectWalletImg from "img/ic_wallet_16.svg";
import "./ConnectWalletButton.scss";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export default function ConnectWalletButton({ children, onClick }: Props) {
  return (
    <button className="connect-wallet-btn" onClick={onClick}>
      <img className="btn-icon" src={connectWalletImg} alt="Connect Wallet" />
      <span className="btn-label">{children}</span>
    </button>
  );
}
