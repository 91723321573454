import "./Referrals.css";
import React from "react";
import { useLocalStorage } from "react-use";
import { Trans, t } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import { Link, useParams } from "react-router-dom";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import Loader from "components/Common/Loader";
import { getPageTitle, isHashZero } from "lib/legacy";
import {
  useReferralsData,
  registerReferralCode,
  useCodeOwner,
  useReferrerTier,
  useUserReferralCode,
} from "domain/referrals";
import JoinReferralCode from "components/Referrals/JoinReferralCode";
import AffiliatesStats from "components/Referrals/AffiliatesStats";
import TradersStats from "components/Referrals/TradersStats";
import AddAffiliateCode from "components/Referrals/AddAffiliateCode";
import { deserializeSampleStats, isRecentReferralCodeNotExpired } from "components/Referrals/referralsHelper";
import { ethers } from "ethers";
import { useLocalStorageSerializeKey } from "lib/localStorage";
import { REFERRALS_SELECTED_TAB_KEY } from "config/localStorage";
import { useChainId } from "lib/chains";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ReferralHeader } from "components/PageHeader/referralHeader";
import tradersImg from "img/traders-tab.svg";
import tradersActiveImg from "img/traders-tab-active.svg";
import positionsImg from "img/positions-tab.svg";
import positionsActiveImg from "img/positions-tab-active.svg";

const TRADERS = "Traders";
const REFERRERS = "Referrers";
const TAB_OPTIONS = [REFERRERS, TRADERS];

const LEADER_ICONS = {
  [REFERRERS]: positionsImg,
  [TRADERS]: tradersImg,
};

const LEADER_ACTIVE_ICONS = {
  [REFERRERS]: positionsActiveImg,
  [TRADERS]: tradersActiveImg,
};

function Referrals({ connectWallet, setPendingTxns, pendingTxns }) {
  const { active, account: walletAccount, library } = useWeb3React();
  const { account: queryAccount } = useParams();
  let account;
  if (queryAccount && ethers.utils.isAddress(queryAccount)) {
    account = ethers.utils.getAddress(queryAccount);
  } else {
    account = walletAccount;
  }
  const { chainId } = useChainId();
  const [activeTab, setActiveTab] = useLocalStorage(REFERRALS_SELECTED_TAB_KEY, TRADERS);
  const { data: referralsData, loading } = useReferralsData(chainId, account);
  const [recentlyAddedCodes, setRecentlyAddedCodes] = useLocalStorageSerializeKey([chainId, "REFERRAL", account], [], {
    deserializer: deserializeSampleStats,
  });
  const { userReferralCode, userReferralCodeString, updateOnChainCode } = useUserReferralCode(
    library,
    chainId,
    account
  );
  const { codeOwner } = useCodeOwner(library, chainId, account, userReferralCode);
  const { referrerTier: traderTier } = useReferrerTier(library, chainId, codeOwner);

  function handleCreateReferralCode(referralCode) {
    return registerReferralCode(chainId, referralCode, library, {
      sentMsg: t`Referral code submitted!`,
      failMsg: t`Referral code creation failed.`,
      pendingTxns,
    });
  }

  function renderAffiliatesTab() {
    const isReferralCodeAvailable =
      referralsData?.codes?.length > 0 || recentlyAddedCodes?.filter(isRecentReferralCodeNotExpired).length > 0;
    if (loading) return <Loader />;
    if (isReferralCodeAvailable) {
      return (
        <AffiliatesStats
          referralsData={referralsData}
          handleCreateReferralCode={handleCreateReferralCode}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
          recentlyAddedCodes={recentlyAddedCodes}
          chainId={chainId}
        />
      );
    } else {
      return (
        <AddAffiliateCode
          handleCreateReferralCode={handleCreateReferralCode}
          active={active}
          connectWallet={connectWallet}
          recentlyAddedCodes={recentlyAddedCodes}
          setRecentlyAddedCodes={setRecentlyAddedCodes}
        />
      );
    }
  }

  function renderTradersTab() {
    if (loading) return <Loader />;
    if (isHashZero(userReferralCode) || !account || !userReferralCode) {
      return (
        <JoinReferralCode
          connectWallet={connectWallet}
          active={active}
          setPendingTxns={setPendingTxns}
          pendingTxns={pendingTxns}
          onJoinSuccess={updateOnChainCode}
        />
      );
    }
    return (
      <TradersStats
        userReferralCodeString={userReferralCodeString}
        chainId={chainId}
        referralsData={referralsData}
        setPendingTxns={setPendingTxns}
        pendingTxns={pendingTxns}
        traderTier={traderTier}
      />
    );
  }
  const TAB_OPTION_LABELS = { [REFERRERS]: t`Referrers`, [TRADERS]: t`Traders` };

  return (
    <SEO title={getPageTitle("Referrals")}>
      <div className="default-container page-layout Referrals">
        <ReferralHeader
          heading="Referrals"
          description={
            <>
              <Trans>
                Get fee discounts and earn rebates through the LIF3 Trade referral program.
                <br />
                For more information, please read the{" "}
                <ExternalLink href="https://docs.lif3.com/lif3-trade/referrals">referral program details</ExternalLink>.
              </Trans>
            </>
          }
          url={
            <div className="referrals-tab-container">
              <Tab
                icons={LEADER_ICONS}
                activeIcons={LEADER_ACTIVE_ICONS}
                options={TAB_OPTIONS}
                optionLabels={TAB_OPTION_LABELS}
                option={activeTab}
                type="group"
                setOption={setActiveTab}
                onChange={setActiveTab}
              />
            </div>
          }
        />
        {activeTab === REFERRERS ? renderAffiliatesTab() : renderTradersTab()}
      </div>
    </SEO>
  );
}

export default Referrals;
