import { BSC, FANTOM } from "config/chains";

// Constant to pull zeros from for multipliers
export const zeros = Array.from(Array(256).keys())
  .map((_) => 0)
  .join()
  .replaceAll(",", "");

export const leaderBoardWebSocketInfo = {
  url: "wss://lif3-trade-leaderboard-4ej7qgvz2a-ew.a.run.app/api-ws",
  topics: {
    requestLatestPriceMap: "requestLatestPriceMap",
    requestLeaderboardTopList: "requestLeaderboardTopList",
    requestOpenTrades: "requestOpenTrades",
  },
};

export const BASIS_POINTS_DIVISOR = BigInt(10000);

export const leaderBoardSupportedNetworks = [FANTOM, BSC];
