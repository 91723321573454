import React, { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import AddressDropdown from "../../../AddressDropdown/AddressDropdown";
import ConnectWalletButton from "../../../Common/ConnectWalletButton";
import WrongNetworkButton from "components/Common/WrongNetworkButton";
import "../../Header.css";
import { isHomeSite, getAccountUrl } from "lib/legacy";
import { Trans } from "@lingui/macro";
import { BSC, FANTOM, getChainIcon, getChainName } from "config/chains";
import NetworkDropdown from "../../../NetworkDropdown/NetworkDropdown";
import LanguagePopupHome from "../../../NetworkDropdown/LanguagePopupHome";
import { switchNetwork } from "lib/wallets";
import { useChainId } from "lib/chains";

type Props = {
  openSettings: () => void;
  small?: boolean;
  setWalletModalVisible: (visible: boolean) => void;
  disconnectAccountAndCloseSettings: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

const NETWORK_OPTIONS = [
  {
    label: getChainName(FANTOM),
    value: FANTOM,
    icon: getChainIcon(FANTOM, 24),
    color: "#E841424D",
  },
  {
    label: getChainName(BSC),
    value: BSC,
    icon: getChainIcon(BSC, 24),
    color: "#E841424D",
  },
];

export function AppHeaderUser({
  openSettings,
  small,
  setWalletModalVisible,
  disconnectAccountAndCloseSettings,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();
  const { active, account, chainId: userChainId } = useWeb3React();
  const showConnectionOptions = !isHomeSite();

  useEffect(() => {
    if (active) {
      setWalletModalVisible(false);
    }
  }, [active, setWalletModalVisible]);

  const onNetworkSelect = useCallback(
    (option) => {
      if (option.value === userChainId) {
        return;
      }

      return switchNetwork(option.value, active);
    },
    [userChainId, active]
  );

  const selectorLabel = getChainName(chainId);

  if (!active || !account) {
    return (
      <>
        {showConnectionOptions ? (
          <>
            <ConnectWalletButton onClick={() => setWalletModalVisible(true)}>
              {small ? <Trans>Connect</Trans> : <Trans>Connect Wallet</Trans>}
            </ConnectWalletButton>
            <NetworkDropdown
              small={small}
              networkOptions={NETWORK_OPTIONS}
              selectorLabel={selectorLabel}
              onNetworkSelect={onNetworkSelect}
              openSettings={openSettings}
            />
          </>
        ) : (
          <LanguagePopupHome />
        )}
      </>
    );
  }

  if (chainId !== userChainId) {
    return (
      <>
        {showConnectionOptions ? (
          <WrongNetworkButton
            onClick={() => {
              const currentOption = NETWORK_OPTIONS.find((network) => network.value === chainId);
              onNetworkSelect(currentOption);
            }}
          >
            <Trans>Wrong Network</Trans>
          </WrongNetworkButton>
        ) : (
          <LanguagePopupHome />
        )}
      </>
    );
  }

  const accountUrl = getAccountUrl(chainId, account);

  return (
    <div className="App-header-user">
      {showConnectionOptions ? (
        <>
          <div className="App-header-user-address">
            <AddressDropdown
              openSettings={openSettings}
              account={account}
              accountUrl={accountUrl}
              disconnectAccountAndCloseSettings={disconnectAccountAndCloseSettings}
            />
          </div>
          <NetworkDropdown
            small={small}
            networkOptions={NETWORK_OPTIONS}
            selectorLabel={selectorLabel}
            onNetworkSelect={onNetworkSelect}
            openSettings={openSettings}
          />
        </>
      ) : (
        <LanguagePopupHome />
      )}
    </div>
  );
}
