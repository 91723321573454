export const candleChartStyleOverrides = {
  'mainSeriesProperties.areaStyle.color1': 'rgba(49, 135, 238, 0.22)',
  'mainSeriesProperties.areaStyle.color2': 'rgba(49, 135, 238, 0)',
  'mainSeriesProperties.areaStyle.linecolor': '#000',

  'mainSeriesProperties.candleStyle.upColor': '#52BD97',
  'mainSeriesProperties.candleStyle.borderUpColor': '#52BD97',
  'mainSeriesProperties.candleStyle.wickUpColor': '#52BD97',

  'mainSeriesProperties.candleStyle.downColor': '#E2726B',
  'mainSeriesProperties.candleStyle.borderDownColor': '#E2726B',
  'mainSeriesProperties.candleStyle.wickDownColor': '#E2726B',
}
