import { Menu } from "@headlessui/react";
import { t, Trans } from "@lingui/macro";
import copy from "img/ic_copy_16.svg";
import externalLink from "img/ic_new_link_16.svg";
import disconnect from "img/ic_sign_out_16.svg";
import { helperToast } from "lib/helperToast";
import { shortenAddress } from "lib/legacy";
import settingsIcon from "img/settings-icon.svg";
import { FaChevronDown } from "react-icons/fa";
import { createBreakpoint, useCopyToClipboard } from "react-use";
import "./AddressDropdown.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import Jazzicon from "react-jazzicon";
import language24Icon from "img/ic_language24.svg";
import { useRef, useState } from "react";
import { LANGUAGE_LOCALSTORAGE_KEY } from "config/localStorage";
import { defaultLocale } from "lib/i18n";
import LanguageModalContent from "components/NetworkDropdown/LanguageModalContent";
import ModalWithPortal from "components/Modal/ModalWithPortal";

const LANGUAGE_MODAL_KEY: string = "LANGUAGE";

type Props = {
  account: string;
  accountUrl: string;
  disconnectAccountAndCloseSettings: () => void;
  openSettings: () => void;
};

function AddressDropdown({ account, accountUrl, disconnectAccountAndCloseSettings, openSettings }: Props) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const [, copyToClipboard] = useCopyToClipboard();
  const currentLanguage = useRef(localStorage.getItem(LANGUAGE_LOCALSTORAGE_KEY) || defaultLocale);
  const [activeModal, setActiveModal] = useState<string | null>(null);

  function getModalContent(modalName) {
    switch (modalName) {
      case LANGUAGE_MODAL_KEY:
        return <LanguageModalContent currentLanguage={currentLanguage} />;
      default:
        return;
    }
  }

  function getModalProps(modalName) {
    switch (modalName) {
      case LANGUAGE_MODAL_KEY:
        return {
          className: "language-popup",
          isVisible: activeModal === LANGUAGE_MODAL_KEY,
          setIsVisible: () => setActiveModal(null),
          label: t`Select Language`,
        };
      default:
        return {};
    }
  }

  return (
    <Menu>
      <Menu.Button as="div">
        <button className="App-cta small transparent address-btn">
          <div className="user-avatar">
            <Jazzicon diameter={18} seed={parseInt(account.slice(2, 10), 16)} />
          </div>
          <span className="user-address">{shortenAddress(account, breakpoint === "S" ? 9 : 13)}</span>
          <FaChevronDown size={14} />
        </button>
      </Menu.Button>
      <div>
        <Menu.Items as="div" className="menu-items">
          <Menu.Item>
            <div className="menu-item" onClick={openSettings}>
              <img src={settingsIcon} alt="Settings" />
              <p>
                <Trans>Settings</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={() => setActiveModal(LANGUAGE_MODAL_KEY)}>
              <img className="network-dropdown-icon" src={language24Icon} alt="" />
              <p>
                <Trans>Language</Trans>
              </p>
            </div>
          </Menu.Item>
          <div className="network-dropdown-divider" />
          <Menu.Item>
            <div
              className="menu-item"
              onClick={() => {
                copyToClipboard(account);
                helperToast.success("Address copied to your clipboard");
              }}
            >
              <img src={copy} alt="Copy user address" />
              <p>
                <Trans>Copy Address</Trans>
              </p>
            </div>
          </Menu.Item>
          <Menu.Item>
            <ExternalLink href={accountUrl} className="menu-item">
              <img src={externalLink} alt="Open address in explorer" />
              <p>
                <Trans>View in Explorer</Trans>
              </p>
            </ExternalLink>
          </Menu.Item>
          <Menu.Item>
            <div className="menu-item" onClick={disconnectAccountAndCloseSettings}>
              <img src={disconnect} alt="Disconnect the wallet" />
              <p>
                <Trans>Disconnect</Trans>
              </p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </div>
      <ModalWithPortal {...getModalProps(activeModal)}>{getModalContent(activeModal)}</ModalWithPortal>
    </Menu>
  );
}

export default AddressDropdown;
