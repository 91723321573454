import { t, Trans } from "@lingui/macro";
import "./styles.css";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { Token } from "domain/tokens";
import { addTokenToMetamask } from "lib/wallets";
import metamaskIcon from "img/MetaMask.svg";
import { PLATFORM_TOKENS } from "config/tokens";

type Props = {
  assetSymbol: string;
  assetInfo?: Token;
  btnText: string;
};

function AddTokenBtn({ assetSymbol, assetInfo, btnText }: Props) {
  const { active } = useWeb3React();
  const { chainId } = useChainId();
  const unavailableTokenSymbols =
    {
      42161: ["ETH"],
      43114: ["AVAX"],
    }[chainId] || [];

  return (
    <>
      {active && unavailableTokenSymbols.indexOf(assetSymbol) < 0 && (
        <button
          onClick={() => {
            let token = assetInfo ? { ...assetInfo, image: assetInfo.imageUrl } : PLATFORM_TOKENS[chainId][assetSymbol];
            addTokenToMetamask(token);
          }}
          className="Add-Token-btn"
        >
          <p className="Add-Token-text">
            <Trans>{btnText}</Trans>
          </p>
          <img src={metamaskIcon} className="Add-Token-icon" alt={t`Add to Metamask`} />
        </button>
      )}
    </>
  );
}

export default AddTokenBtn;
