import React from "react";
import { Trans } from "@lingui/macro";
import "./Buy.css";
import TokenCard from "components/TokenCard/TokenCard";
import buyLIF3Icon from "img/ic_lif3_40.svg";
import SEO from "components/Common/SEO";
import { getPageTitle } from "lib/legacy";

export default function BuyGMXGLP() {
  return (
    <SEO title={getPageTitle("Buy LIF3 or LLP")}>
      <div className="BuyGMXGLP page-layout">
        <div className="BuyGMXGLP-container default-container">
          <div className="section-title-block">
            <div className="section-title-icon">
              <img src={buyLIF3Icon} alt="buyGMXIcon" />
            </div>
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Buy LIF3 or LLP</Trans>
              </div>
            </div>
          </div>
          <TokenCard />
        </div>
      </div>
    </SEO>
  );
}
