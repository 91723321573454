import React from "react";
import "./styles.css";

type Props = {
  heading: string | React.ReactElement;
  description: string | React.ReactElement;
  btnText?: string;
  url?: string | React.ReactElement;
};

export const ReferralHeader = ({ heading, description, url }: Props) => {
  return (
    <div className="Page-header-box">
      <div className="Page-header-col">
        <h1 className="Page-heading">{heading}</h1>
        <p className="Page-description">{description}</p>
      </div>
      {url}
    </div>
  );
};
