import { ethers } from "ethers";
import { getContract } from "./contracts";
import { BSC, FANTOM } from "./chains";
import { Token } from "domain/tokens";

export const TOKENS: { [chainId: number]: Token[] } = {
  [FANTOM]: [
    {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/FTM.svg",
    },
    {
      name: "Wrapped FTM",
      symbol: "WFTM",
      decimals: 18,
      address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      isWrapped: true,
      baseSymbol: "FTM",
      imageUrl: "https://assets.lif3.com/swap/tokens/WFTM.svg",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x74b23882a30290451A17c44f4F05243b6b58C76d",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/ETH.svg",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x321162Cd933E2Be498Cd2267a90534A804051b11",
      decimals: 8,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/BTC.svg",
    },
    {
      name: "SpookySwap",
      symbol: "BOO",
      address: "0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/BOO.svg",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      decimals: 6,
      isStable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/USDC.svg",
    },
  ],
  [BSC]: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      address: ethers.constants.AddressZero,
      isNative: true,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/BNB.svg",
    },
    {
      name: "Wrapped BNB",
      symbol: "WBNB",
      decimals: 18,
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      isWrapped: true,
      baseSymbol: "WBNB",
      imageUrl: "https://assets.lif3.com/swap/tokens/WBNB.svg",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/ETH.svg",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      decimals: 18,
      isShortable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/BTC.svg",
    },
    {
      name: "BUSD",
      symbol: "BUSD",
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/BUSD.svg",
    },
    {
      name: "USDT",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimals: 18,
      isStable: true,
      imageUrl: "https://assets.lif3.com/swap/tokens/USDT.svg",
    },
  ],
};

export const ADDITIONAL_TOKENS: { [chainId: number]: Token[] } = {
  [FANTOM]: [
    {
      name: "LIF3",
      symbol: "LIF3",
      address: getContract(FANTOM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LIF3.svg",
    },
    {
      name: "Escrowed LIF3",
      symbol: "esLIF3",
      address: getContract(FANTOM, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "LIF3 LP",
      symbol: "LLP",
      address: getContract(FANTOM, "GLP"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LLP.svg",
    },
  ],
  [BSC]: [
    {
      name: "LIF3",
      symbol: "LIF3",
      address: getContract(BSC, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LIF3.svg",
    },
    {
      name: "Escrowed LIF3",
      symbol: "esLIF3",
      address: getContract(BSC, "ES_GMX"),
      decimals: 18,
    },
    {
      name: "LIF3 LP",
      symbol: "LLP",
      address: getContract(BSC, "GLP"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LLP.svg",
    },
  ],
};

export const PLATFORM_TOKENS: { [chainId: number]: { [symbol: string]: Token } } = {
  [FANTOM]: {
    // arbitrum
    GMX: {
      name: "LIF3",
      symbol: "LIF3",
      address: getContract(FANTOM, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LIF3.svg",
    },
    GLP: {
      name: "LIF3 LP",
      symbol: "LLP",
      address: getContract(FANTOM, "GLP"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LLP.svg",
    },
  },
  [BSC]: {
    // arbitrum
    GMX: {
      name: "LIF3",
      symbol: "LIF3",
      address: getContract(BSC, "GMX"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LIF3.svg",
    },
    GLP: {
      name: "LIF3 LP",
      symbol: "LLP",
      address: getContract(BSC, "GLP"),
      decimals: 18,
      imageUrl: "https://assets.lif3.com/swap/tokens/LLP.svg",
    },
  },
};

export const ICONLINKS = {
  [FANTOM]: {
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/fantom",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
      fantom: "https://ftmscan.com/address/0x74b23882a30290451A17c44f4F05243b6b58C76d",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
      fantom: "https://bscscan.com/address/",
    },
    WFTM: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-fantom",
      fantom: "https://ftmscan.com/address/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    },
    USDC: {
      coingecko: "https://www.coingecko.com/en/coins/usd-coin",
      fantom: "https://bscscan.com/address/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
  },
  [BSC]: {
    FTM: {
      coingecko: "https://www.coingecko.com/en/coins/bnb",
    },
    ETH: {
      coingecko: "https://www.coingecko.com/en/coins/weth",
    },
    BTC: {
      coingecko: "https://www.coingecko.com/en/coins/wrapped-bitcoin",
    },
    BUSD: {
      coingecko: "https://www.coingecko.com/en/coins/binance-usd",
    },
    USDT: {
      coingecko: "https://www.coingecko.com/en/coins/tether",
    },
  },
};

export const GLP_POOL_COLORS = {
  ETH: "#6062a6",
  BTC: "#F7931A",
  BOO: "#6665DD",
  USDC: "#2775CA",
  FTM: "#1969FF",
  BNB: "#F0B90B",
  USDT: "#50AF95",
  BUSD: "#EEE809",
};

export const TOKENS_MAP: { [chainId: number]: { [address: string]: Token } } = {};
export const TOKENS_BY_SYMBOL_MAP: { [chainId: number]: { [symbol: string]: Token } } = {};
export const WRAPPED_TOKENS_MAP: { [chainId: number]: Token } = {};
export const NATIVE_TOKENS_MAP: { [chainId: number]: Token } = {};

const CHAIN_IDS = [FANTOM, BSC];

for (let j = 0; j < CHAIN_IDS.length; j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId: number) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId: number) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId: number) {
  return TOKENS[chainId];
}

export function isValidToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId: number, address: string) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId: number, symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId: number) {
  return TOKENS[chainId].filter((token) => token.symbol !== "USDG");
}

export function getVisibleTokens(chainId: number) {
  return getWhitelistedTokens(chainId).filter((token) => !token.isWrapped && !token.isTempHidden);
}

export function getNormalizedTokenSymbol(tokenSymbol) {
  if (["WBTC", "WETH", "WAVAX"].includes(tokenSymbol)) {
    return tokenSymbol.substr(1);
  } else if (tokenSymbol === "BTC.b") {
    return "BTC";
  }
  return tokenSymbol;
}

const AVAILABLE_CHART_TOKENS = {
  [BSC]: [],
  [FANTOM]: ["FTM", "ETH", "BTC", "BOO"],
};

export function isChartAvailabeForToken(chainId: number, tokenSymbol: string) {
  const token = getTokenBySymbol(chainId, tokenSymbol);
  if (!token) return false;
  return (token.isStable || AVAILABLE_CHART_TOKENS[chainId]?.includes(getNormalizedTokenSymbol(tokenSymbol))) ?? false;
}
