export const config = {
  disabled_features: [
    "use_localstorage_for_settings",
    "control_bar",
    "header_widget",
    "timeframes_toolbar",
    "display_market_status",
    // 'context_menus',
    "border_around_the_chart",
    // 'pane_context_menu',
    "symbol_info",
    "compare_symbol",
    "legend_context_menu",
    "popup_hints",
    "symbol_search_hot_key",
    "timeframes_toolbar",
    // 'left_toolbar',
    // 'main_series_scale_menu'
  ],
  enabled_features: [],
  client_id: "tradingview.com",
  user_id: "public_user_id",
  studies_overrides: {
    "volume.volume.color.0": "#7A403C",
    "volume.volume.color.1": "#306C57",
    "volume.precision": 5,
  },
  custom_css_url: "css/custom_chart_styles.css?t=1",
};
