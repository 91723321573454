import React, { useEffect, useMemo } from "react";
import { FiX } from "react-icons/fi";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { HeaderLink } from "../../HeaderLink";
import "./AppHeaderLinks.css";
import { isHomeSite } from "lib/legacy";
import ExternalLink from "components/ExternalLink/ExternalLink";
import logoImg from "img/lifLogo.svg";
import tradeIcon from "img/trade-icon.svg";
import dashboardIcon from "img/dashboard-icon.svg";
import lpIcon from "img/liquidity.svg";
import earnIcon from "img/earn-icon.svg";
import buyIcon from "img/lp-icon.svg";
import refIcon from "img/ref-icon.svg";
import LeaderboardIcon from "img/leaderboard-icon.svg";
import settingsIcon from "img/settings-icon.svg";
import statsIcon from "img/stats.svg";
import { IoWaterOutline } from "react-icons/io5";
import lif3Icon from "img/lif3-symbol.svg";
import { useChainId } from "lib/chains";
import { BSC, isSupportedChain } from "config/chains";

type Props = {
  small?: boolean;
  clickCloseIcon?: () => void;
  openSettings?: () => void;
  redirectPopupTimestamp: number;
  showRedirectModal: (to: string) => void;
};

export function AppHeaderLinks({
  small,
  openSettings,
  clickCloseIcon,
  redirectPopupTimestamp,
  showRedirectModal,
}: Props) {
  const { chainId } = useChainId();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId]);
  const setSelectedChainId = (chainId: number) => {
    if (!isSupportedChain(chainId)) {
      return;
    }

    if (pathname !== "/") {
      searchParams.set("chainId", `${chainId}`);
      history.push({
        search: searchParams.toString(),
      });
    }
  };
  useEffect(() => {
    setSelectedChainId(chainId);
  }, []);

  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <Link className="App-header-link-main" to="/">
            <img src={logoImg} alt="LIF3-Trade Logo" />
          </Link>
          <div
            className="App-header-menu-icon-block mobile-cross-menu"
            onClick={() => clickCloseIcon && clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
        </div>
      )}
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/trade", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={tradeIcon} className={"App-header-link-icon"} alt="Trade" />
          <Trans>Trade</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/liquidity", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {/* <img src={lpIcon} className={"App-header-link-icon"} alt="LP" /> */}
          <IoWaterOutline className={"App-header-link-icon"} />
          <Trans>Liquidity</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/earn", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={earnIcon} className={"App-header-link-icon"} alt="Earn" />
          <Trans>Earn</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/buy", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={buyIcon} className={"App-header-link-icon"} alt="Buy" />
          <Trans>Buy</Trans>
        </HeaderLink>
      </div>

      <div className="App-header-link-container">
        <ExternalLink href={`https://trade-stats.lif3.com${chainId === BSC ? "/bnb" : ""}`}>
          <img src={statsIcon} className={"App-header-link-icon"} alt="Stats" />
          <Trans>Stats</Trans>
        </ExternalLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/dashboard", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={dashboardIcon} className={"App-header-link-icon"} alt="Dashboard" />
          <Trans>Dashboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/referrals", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={refIcon} className={"App-header-link-icon"} alt="Earn" />
          <Trans>Referrals</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <HeaderLink
          to={{ pathname: "/leaderboard", search: chainIdOnlysearchParams.toString() }}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          <img src={LeaderboardIcon} className={"App-header-link-icon"} alt="Earn" />
          <Trans>Leaderboard</Trans>
        </HeaderLink>
      </div>
      <div className="App-header-link-container">
        <ExternalLink href="https://lif3.com/">
          <img src={lif3Icon} className={"App-header-link-icon"} alt="Lif3.com" />
          <Trans>Lif3.com</Trans>
        </ExternalLink>
      </div>
      {small && !isHomeSite() && (
        <div className="App-header-link-container">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={openSettings}>
            <img src={settingsIcon} className={"App-header-link-icon"} alt="Earn" />
            <Trans>Settings</Trans>
          </a>
        </div>
      )}
    </div>
  );
}
