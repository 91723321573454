import React from "react";
import "./LeaderBoardCard.css";
import Jazzicon from "react-jazzicon";
import { Trans } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import { shortenAddress } from "lib/legacy";
import { createBreakpoint } from "react-use";
import { LeaderBoardPositionExtended } from "./types";
import { calculatePositionsInfo } from "./utils";
import { ethers } from "ethers";
import { TokenInfo } from "domain/tokens";
import { NETWORK_METADATA } from "config/chains";
import { networkImg, tokenImg } from "./constants";

type Props = {
  position: LeaderBoardPositionExtended;
  combinedInfoTokens: { [x: string]: TokenInfo };
};

export function LeaderBoardCard({ position, combinedInfoTokens }: Props) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { formattedSize, formattedLeverage, formattedAveragePrice, formattedRealisedPnl, realisedPnlNum } =
    calculatePositionsInfo(position);

  const indexTokenAddressCheckSummed = ethers.utils.getAddress(position?.indexToken ?? "");
  const indexTokenInfo = combinedInfoTokens[indexTokenAddressCheckSummed];

  return (
    <div className="LeaderBoardCard">
      <span className="LeaderBoardCard-row">
        <span className="LeaderBoardCard-sub-row">
          <div className="LeaderBoardCard-logo">
            <Jazzicon diameter={24} seed={parseInt((position?.account ?? "0").slice(2, 10), 16)} />
          </div>
          <span className="LeaderBoardCard-name">{shortenAddress(position?.account, breakpoint === "S" ? 9 : 13)}</span>
        </span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle="Market"
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>The asset being traded</span>
              </Trans>
            );
          }}
        />
        <span className="LeaderBoardCard-value">
          <img
            src={`https://assets.lif3.com/swap/tokens/${
              tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol
            }.svg`}
            alt={tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol}
            width="20px"
          />
          <span className="LeaderBoardCard-price">{tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol}</span>
        </span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle="Side & Lev"
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>Amount of leverage taken on trade</span>
              </Trans>
            );
          }}
        />
        <span className={`LeaderBoardCard-value ${position?.isLong ? "positive" : "negative"}`}>{`${
          position?.isLong ? "Long" : "Short"
        } ${formattedLeverage}x`}</span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle="Size"
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>Position size with leverage</span>
              </Trans>
            );
          }}
        />
        <span className="LeaderBoardCard-value">{`$${formattedSize}`}</span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle="Entry Price"
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>The price at which the trade was opened</span>
              </Trans>
            );
          }}
        />
        <span className="LeaderBoardCard-value">{`$${formattedAveragePrice}`}</span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle="PNL"
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>The account profits or losses after deducting funding payments</span>
              </Trans>
            );
          }}
        />
        <span className={`LeaderBoardCard-value ${realisedPnlNum < 0 ? "negative" : "positive"}`}>
          {`${realisedPnlNum < 0 ? "" : "+"}${formattedRealisedPnl}`}
        </span>
      </span>
      <span className="LeaderBoardCard-row">
        <span className="Tooltip-handle">Chain</span>
        <span className="LeaderBoardCard-value">
          <img
            src={`https://assets.lif3.com/swap/tokens/${networkImg[position?.chain ?? 0]}.svg`}
            alt={networkImg[position?.chain ?? 0]}
            width="20px"
          />
          <span className="LeaderBoardCard-price">{NETWORK_METADATA[position?.chain ?? 0]?.chainName}</span>
        </span>
      </span>
    </div>
  );
}
