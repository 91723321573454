import { ReactNode } from "react";
import wrongNetworkImg from "img/ic_wallet_white_16.svg";
import "./WrongNetworkButton.scss";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export default function WringNetworkButton({ children, onClick }: Props) {
  return (
    <button className="wrong-network-btn" onClick={onClick}>
      <img className="btn-icon" src={wrongNetworkImg} alt="Wrong Network" />
      <span className="btn-label">{children}</span>
    </button>
  );
}
