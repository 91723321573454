import { initialState, TRADERS_ACTIONS } from "./constants";
import { ActionType, LeaderboardState } from "./types";

export function leaderBoardReducer(state: LeaderboardState, action: ActionType) {
  switch (action.type) {
    case TRADERS_ACTIONS.UPDATE_TRADERS_ACTION:
      return {
        ...state,
        [action.payload.chainId]: action.payload.data,
      };
    default:
      console.error(`no such action ${action.type}`);

      return initialState;
  }
}
