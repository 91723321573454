import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export function getGmxGraphClient(chainId: number) {
  if (!SUBGRAPH_URLS[chainId]) {
    throw new Error(`Unsupported chain ${chainId}`);
  }

  const graphClient = createClient(SUBGRAPH_URLS[chainId].stats);

  return graphClient;
}

export function getRefferalsGraphClient(chainId: number) {
  if (!SUBGRAPH_URLS[chainId]) {
    throw new Error(`Unsupported chain ${chainId}`);
  }

  const referralsGraphClient = createClient(SUBGRAPH_URLS[chainId].referrals);

  return referralsGraphClient;
}
