import React from "react";

import ChartTokenSelector from "components/Exchange/ChartTokenSelector";

import { CHART_PERIODS, USD_DECIMALS } from "lib/legacy";
import { formatAmount, numberWithCommas } from "lib/numbers";
import cx from "classnames";
import { Trans } from "@lingui/macro";
import { useChartPrices } from "domain/prices";
import { useLocalStorageSerializeKey } from "lib/localStorage";

const DEFAULT_PERIOD = "4h";

export const ChartHeader: React.FC<any> = (props) => {
  const { chartToken, onSelectToken, swapOption, infoTokens, chainId } = props;

  let high;
  let low;
  let deltaPrice;
  let delta;
  let deltaPercentage;
  let deltaPercentageStr;

  const now = parseInt(String(Date.now() / 1000));
  const timeThreshold = now - 24 * 60 * 60;

  let [period] = useLocalStorageSerializeKey([chainId, "Chart-period"] as any, DEFAULT_PERIOD);
  if (period && !(period in CHART_PERIODS)) {
    period = DEFAULT_PERIOD;
  }

  const currentAveragePrice =
    chartToken.maxPrice && chartToken.minPrice ? chartToken.maxPrice.add(chartToken.minPrice).div(2) : null;

  const [priceData] = useChartPrices(chainId, chartToken.symbol, chartToken.isStable, period, currentAveragePrice);

  if (priceData) {
    for (let i = priceData.length - 1; i > 0; i--) {
      const price = priceData[i];
      if (price.time < timeThreshold) {
        break;
      }
      if (!low) {
        low = price.low;
      }
      if (!high) {
        high = price.high;
      }

      if (price.high > high) {
        high = price.high;
      }
      if (price.low < low) {
        low = price.low;
      }

      deltaPrice = price.open;
    }
  }

  if (deltaPrice && currentAveragePrice) {
    const average = parseFloat(formatAmount(currentAveragePrice, USD_DECIMALS, 3));
    delta = average - deltaPrice;
    deltaPercentage = (delta * 100) / average;
    if (deltaPercentage > 0) {
      deltaPercentageStr = `+${deltaPercentage.toFixed(2)}%`;
    } else {
      deltaPercentageStr = `${deltaPercentage.toFixed(2)}%`;
    }
    if (deltaPercentage === 0) {
      deltaPercentageStr = "0.00";
    }
  }

  return (
    <div className="ExchangeChart-top App-box App-box-border">
      <div className="ExchangeChart-top-inner">
        <div>
          <div className="ExchangeChart-title">
            <ChartTokenSelector
              chainId={chainId}
              selectedToken={chartToken}
              swapOption={swapOption}
              infoTokens={infoTokens}
              onSelectToken={onSelectToken}
              className="chart-token-selector"
            />
          </div>
        </div>
        <div>
          <div className="ExchangeChart-main-price">
            {chartToken.maxPrice && formatAmount(chartToken.maxPrice, USD_DECIMALS, 4, true)}
          </div>
          <div className="ExchangeChart-info-label">
            ${chartToken.minPrice && formatAmount(chartToken.minPrice, USD_DECIMALS, 4, true)}
          </div>
        </div>
        <div>
          <div className="ExchangeChart-info-label">
            <Trans>24h Change</Trans>
          </div>
          <div
            className={cx("ExchangeChart-info-text", { positive: deltaPercentage > 0, negative: deltaPercentage < 0 })}
          >
            {!deltaPercentageStr && "-"}
            {deltaPercentageStr && deltaPercentageStr}
          </div>
        </div>
        <div className="ExchangeChart-additional-info">
          <div className="ExchangeChart-info-label">
            <Trans>24h High</Trans>
          </div>
          <div className="ExchangeChart-info-text">
            {!high && "-"}
            {high && numberWithCommas(high.toFixed(4))}
          </div>
        </div>
        <div className="ExchangeChart-additional-info">
          <div className="ExchangeChart-info-label">
            <Trans>24h Low</Trans>
          </div>
          <div className="ExchangeChart-info-text">
            {!low && "-"}
            {low && numberWithCommas(low.toFixed(4))}
          </div>
        </div>
      </div>
      <div className="ExchangeChart-view-toggle">
        <button
          className={cx("ExchangeChart-view-btn", props.selectedChart === "simpleChart" && "active")}
          onClick={() => props.setSelectedChart("simpleChart")}
        >
          Standard
        </button>
        <button
          className={cx("ExchangeChart-view-btn", props.selectedChart === "tradingView" && "active")}
          onClick={() => props.setSelectedChart("tradingView")}
        >
          Trading View
        </button>
      </div>
    </div>
  );
};
