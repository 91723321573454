import "./LeaderBoard.css";
import React from "react";
import { Trans, t } from "@lingui/macro";
import SEO from "components/Common/SEO";
import Tab from "components/Tab/Tab";
import { getPageTitle } from "lib/legacy";
import { LEADERBOARD_SELECTED_TAB_KEY } from "config/localStorage";
import { useLocalStorage } from "react-use";
import tradersImg from "img/traders-tab.svg";
import tradersActiveImg from "img/traders-tab-active.svg";
import positionsImg from "img/positions-tab.svg";
import positionsActiveImg from "img/positions-tab-active.svg";
import { LeaderBoardTradersTable } from "components/LeaderBoardTraders/LeaderBoardTradersTable";
import { LeaderBoardPositionsTable } from "components/LeaderBoardPositions/LeaderBoardPositionsTable";

const TRADERS = "Traders";
const POSITIONS = "Positions";
const TAB_OPTIONS = [TRADERS, POSITIONS];

const LEADER_ICONS = {
  [TRADERS]: tradersImg,
  [POSITIONS]: positionsImg,
};

const LEADER_ACTIVE_ICONS = {
  [TRADERS]: tradersActiveImg,
  [POSITIONS]: positionsActiveImg,
};

function LeaderBoard({}) {
  const [activeTab, setActiveTab] = useLocalStorage(LEADERBOARD_SELECTED_TAB_KEY, TRADERS);
  const TAB_OPTION_LABELS = { [TRADERS]: t`Traders`, [POSITIONS]: t`Positions` };

  return (
    <SEO title={getPageTitle("LeaderBoard")}>
      <div className="default-container page-layout LeaderBoard">
        <div className="LeaderBoard-header-box">
          <div className="section-title-block">
            <div className="section-title-icon" />
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Leaderboard</Trans>
              </div>
              <div className="Page-description">
                <Trans>
                  View the best traders and top positions on Lif3 Trade.
                </Trans>
              </div>
            </div>
          </div>
          <div className="LeaderBoard-tab-container">
            <Tab
              icons={LEADER_ICONS}
              activeIcons={LEADER_ACTIVE_ICONS}
              options={TAB_OPTIONS}
              optionLabels={TAB_OPTION_LABELS}
              option={activeTab}
              type="group"
              setOption={setActiveTab}
              onChange={setActiveTab}
            />
          </div>
        </div>
        {activeTab === TRADERS ? <LeaderBoardTradersTable /> : <LeaderBoardPositionsTable />}
      </div>
    </SEO>
  );
}

export default LeaderBoard;
