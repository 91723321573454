import React, { useState, useEffect, useReducer, useMemo } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Jazzicon from "react-jazzicon";
import Tab from "components/Tab/Tab";
import { leaderBoardSupportedNetworks, leaderBoardWebSocketInfo } from "lib/constants";
import { LeaderBoardTrader } from "./types";
import { calculateTraderInfo } from "./utils";
import {
  LEADER_BOARD_TIME_INTERVAL_FILTER_OPTIONS,
  LEADER_BOARD_TIME_INTERVAL_FILTER_LABELS,
  initialState,
} from "./constants";
import "./LeaderBoardTradersTable.css";
import { shortenAddress } from "lib/legacy";
import { leaderBoardReducer } from "./reducers";
import { updateTradersAction } from "./actions";
import { LeaderBoardCard } from "./LeaderBoardCard";

export function LeaderBoardTradersTable() {
  const [state, dispatch] = useReducer(leaderBoardReducer, initialState);
  const [timeInterval, setTimeInterval] = useState(LEADER_BOARD_TIME_INTERVAL_FILTER_OPTIONS[0]);
  const [sortBy, setSortBy] = useState<"size" | "realisedPnl">("size");
  const [sortDirection, setSortDirection] = useState<"asc" | "dsc">("asc");

  useEffect(() => {
    const getLeaderBoardTopListRequestBody = (chainId: number) => ({
      topic: leaderBoardWebSocketInfo.topics.requestLeaderboardTopList,
      body: {
        timeInterval: Number(timeInterval), // time interval to fetch results
        offset: 0, // skips N results
        pageSize: 20, // displays N results
        sortBy: sortBy, // Possible values: size, realisedPnl,
        chain: chainId, // chain ID,
        sortDirection: sortDirection, // Possible values: asc, dsc
      },
    });
    const ws = new WebSocket(leaderBoardWebSocketInfo.url);

    ws.onopen = () => {
      leaderBoardSupportedNetworks.forEach((chainId) => {
        ws.send(JSON.stringify(getLeaderBoardTopListRequestBody(chainId)));
      });
    };
    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      dispatch(updateTradersAction(response.body.chain, response.body.page));
    };
    ws.onerror = (error) => {
      console.error(error);
    };
    ws.onclose = (event) => {
      console.info(
        `websocket connection was closed with the reason: ${event.reason}, code: ${event.code} and wasClean: ${event.wasClean}`
      );

      ws.close();
    };

    return () => {
      ws.close();
    };
  }, [timeInterval, sortBy, sortDirection]);

  const handleSizeClick = () => {
    setSortBy("size");
    setSortDirection((prevState) => (prevState === "asc" ? "dsc" : "asc"));
  };
  const handlePnlClick = () => {
    setSortBy("realisedPnl");
    setSortDirection((prevState) => (prevState === "asc" ? "dsc" : "asc"));
  };

  const sortedData = useMemo(
    () =>
      Object.keys(state)
        .reduce<LeaderBoardTrader[]>((acc, cur) => [...acc, ...state[cur]], [])
        .sort(
          (a, b) =>
            parseFloat(((sortDirection === "asc" ? b : a)[sortBy] ?? "").replace(/,/g, "")) -
            parseFloat(((sortDirection === "asc" ? a : b)[sortBy] ?? "").replace(/,/g, ""))
        ),
    [state, sortBy, sortDirection]
  );

  return (
    <div className="LeaderBoard-content-box">
      <Tab
        options={LEADER_BOARD_TIME_INTERVAL_FILTER_OPTIONS}
        optionLabels={LEADER_BOARD_TIME_INTERVAL_FILTER_LABELS}
        option={timeInterval}
        type="group LeaderBoard-filter-tabs"
        setOption={setTimeInterval}
        onChange={setTimeInterval}
      />
      <div className="LeaderBoardTable">
        <table className="LeaderBoardTable-table">
          <thead className="LeaderBoardTable-table-header">
            <tr className="LeaderBoardTable-header-row">
              <td className="LeaderBoardTable-header-cell">Name</td>
              <td className="LeaderBoardTable-header-cell">
                <button
                  onClick={handleSizeClick}
                  className={`LeaderBoardTable-header-btn ${sortBy === "size" ? "is-active" : ""}`}
                >
                  Trading Volume
                  {sortBy === "size" && sortDirection === "asc" ? (
                    <AiOutlineArrowUp className="LeaderBoardTable-header-icon" />
                  ) : (
                    <AiOutlineArrowDown className="LeaderBoardTable-header-icon" />
                  )}
                </button>
              </td>
              <td className="LeaderBoardTable-header-cell">
                <button className="LeaderBoardTable-header-btn">
                  Average Lev.
                  {/* <AiOutlineArrowDown className="LeaderBoardTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardTable-header-cell">
                <button className="LeaderBoardTable-header-btn">
                  Win
                  {/* <AiOutlineArrowDown className="LeaderBoardTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardTable-header-cell">
                <button className="LeaderBoardTable-header-btn">
                  Loss
                  {/* <AiOutlineArrowDown className="LeaderBoardTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardTable-header-cell">
                <button
                  onClick={handlePnlClick}
                  className={`LeaderBoardTable-header-btn ${sortBy === "realisedPnl" ? "is-active" : ""}`}
                >
                  PNL w. Fees
                  {sortBy === "realisedPnl" && sortDirection === "asc" ? (
                    <AiOutlineArrowUp className="LeaderBoardTable-header-icon" />
                  ) : (
                    <AiOutlineArrowDown className="LeaderBoardTable-header-icon" />
                  )}
                </button>
              </td>
            </tr>
          </thead>
          <tbody className="LeaderBoardTable-table-body">
            {!!sortedData?.length &&
              sortedData.map((trader, index) => {
                const { formattedSize, formattedLeverage, formattedRealisedPnl, realisedPnlNum, lossTradeCount } =
                  calculateTraderInfo(trader);

                return (
                  <tr className="LeaderBoardTable-row" key={trader?.account ?? 0}>
                    <td className="LeaderBoardTable-cell">
                      <span className="LeaderBoardTable-cell-row">
                        <span>{index + 1}</span>
                        <div className="LeaderBoardTable-cell-logo">
                          <Jazzicon diameter={24} seed={parseInt((trader?.account ?? "0").slice(2, 10), 16)} />
                        </div>
                        <span className="LeaderBoardTable-cell-name">{shortenAddress(trader?.account, 13)}</span>
                      </span>
                    </td>
                    <td className="LeaderBoardTable-cell">{`$${formattedSize}`}</td>
                    <td className="LeaderBoardTable-cell">{`${formattedLeverage}x`}</td>
                    <td className="LeaderBoardTable-cell">{trader?.winTradeCount}</td>
                    <td className="LeaderBoardTable-cell">{lossTradeCount}</td>
                    <td className="LeaderBoardTable-cell">
                      <span className={`LeaderBoardTable-cell-price ${realisedPnlNum < 0 ? "negative" : "positive"}`}>
                        {`${realisedPnlNum < 0 ? "" : "+"}${formattedRealisedPnl}`}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ul className="LeaderBoard-mobile-list">
        {!!sortedData?.length &&
          sortedData.map((trader) => (
            <li className="LeaderBoard-mobile-item" key={trader?.account}>
              <LeaderBoardCard trader={trader} />
            </li>
          ))}
      </ul>
    </div>
  );
}
