export const getDefaultOverrides = (isDarkMode: boolean) => {
  return {
    "mainSeriesProperties.areaStyle.priceSource": "close",

    "paneProperties.vertGridProperties.color": isDarkMode ? "rgba(233, 233, 233, 0.05)" : "rgba(17, 17, 17, 0.05)",
    "paneProperties.horzGridProperties.color": isDarkMode ? "rgba(233, 233, 233, 0.05)" : "rgba(17, 17, 17, 0.05)",
    "paneProperties.background": "#0F1526",
    "paneProperties.legendProperties.showStudyArguments": false,
    "paneProperties.legendProperties.showSeriesTitle": false,
    "paneProperties.legendProperties.showSeriesOHLC": true,

    "scalesProperties.showLeftScale": true,
    "scalesProperties.showRightScale": false,
    "scalesProperties.backgroundColor": "#0F1526",
    "scalesProperties.fontSize": 12,
    "scalesProperties.lineColor": "transparent",
    "scalesProperties.textColor": isDarkMode ? "rgba(233, 233, 233, 0.3)" : "rgba(17, 17, 17, 0.3)",
  };
};
