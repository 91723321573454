import { BSC, FANTOM } from "./chains";

export const SUBGRAPH_URLS = {
  [FANTOM]: {
    stats: "https://hsapi.fura.org/subgraphs/name/lifetrade-stats",
    referrals: "https://hsapi.fura.org/subgraphs/name/lifetrade-referrals",
  },
  [BSC]: {
    stats: "https://api.thegraph.com/subgraphs/name/ruvlol/lifetrade-bsc1-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/ruvlol/lifetrade-bsc1-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
