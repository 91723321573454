import React from "react";
import "./LeaderBoardCard.css";
import Jazzicon from "react-jazzicon";
import { Trans } from "@lingui/macro";
import Tooltip from "components/Tooltip/Tooltip";
import { shortenAddress } from "lib/legacy";
import { createBreakpoint } from "react-use";
import { LeaderBoardTrader } from "./types";
import { calculateTraderInfo } from "./utils";

type Props = {
  trader: LeaderBoardTrader;
};

export function LeaderBoardCard({ trader }: Props) {
  const useBreakpoint = createBreakpoint({ L: 600, M: 550, S: 400 });
  const breakpoint = useBreakpoint();
  const { formattedSize, formattedLeverage, formattedRealisedPnl, realisedPnlNum, lossTradeCount } =
    calculateTraderInfo(trader);

  return (
    <div className="LeaderBoardCard">
      <span className="LeaderBoardCard-row">
        <span className="LeaderBoardCard-sub-row">
          <div className="LeaderBoardCard-logo">
            <Jazzicon diameter={24} seed={parseInt((trader?.account ?? "0").slice(2, 10), 16)} />
          </div>
          <span className="LeaderBoardCard-name">{shortenAddress(trader?.account, breakpoint === "S" ? 9 : 13)}</span>
        </span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle={`Volume & Lev.`}
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>Trading volume and the average leverage behind the volume</span>
              </Trans>
            );
          }}
        />
        <span className="LeaderBoardCard-value">
          <span className="LeaderBoardCard-price">{`$${formattedSize}`}</span>
          <span className="LeaderBoardCard-leverage">{`(${formattedLeverage}x)`}</span>
        </span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle={`Win / Loss`}
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>
                  Win: The number of positions settled in profits Loss: The number of positions settled in losses
                </span>
              </Trans>
            );
          }}
        />
        <span className="LeaderBoardCard-value">{`${trader?.winTradeCount} / ${lossTradeCount}`}</span>
      </span>
      <span className="LeaderBoardCard-row">
        <Tooltip
          handle={`PNL w. Fees`}
          position="left-bottom"
          renderContent={() => {
            return (
              <Trans>
                <span>The account profits or losses after deducting position fees and funding payments</span>
              </Trans>
            );
          }}
        />
        <span className={`LeaderBoardCard-value ${realisedPnlNum < 0 ? "negative" : "positive"}`}>
          {`${realisedPnlNum < 0 ? "" : "+"}${formattedRealisedPnl}`}
        </span>
      </span>
    </div>
  );
}
