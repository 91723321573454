import { BASIS_POINTS_DIVISOR, zeros } from "lib/constants";
import { USD_DECIMALS } from "lib/legacy";
import { formatAmount } from "lib/numbers";
import { LeaderBoardTrader } from "./types";

function getMultiplier(decimals: number): string {
  if (decimals >= 0 && decimals <= 256 && !(decimals % 1)) {
    return "1" + zeros.substring(0, decimals);
  }

  throw new Error("invalid decimal size");
}

export function formatFixed(value: bigint, decimals = 18): number {
  const multiplier = getMultiplier(decimals);
  const multiplierBn = BigInt(multiplier);
  let parsedValue = "";

  const negative = value < BigInt(0);
  if (negative) {
    value *= BigInt(-1);
  }

  let fraction = (value % multiplierBn).toString();

  while (fraction.length < multiplier.length - 1) {
    fraction = "0" + fraction;
  }

  const matchFractions = fraction.match(/^([0-9]*[1-9]|0)(0*)/)!;
  fraction = matchFractions[1];

  const whole = (value / multiplierBn).toString();

  parsedValue = whole + "." + fraction;

  if (negative) {
    parsedValue = "-" + parsedValue;
  }

  return Number(parsedValue);
}

export function readableNumber(ammount: number, showDecimals = true) {
  const parts = ammount.toString().split(".");
  const [whole = "", decimal = ""] = parts;

  if (whole === "" && decimal === "") {
    return "-";
  }

  if (whole.replace(/^-/, "") === "0" || whole.length < 3) {
    const shortDecimal = decimal.slice(0, 2);
    return whole + (shortDecimal && showDecimals ? "." + shortDecimal : "");
  }

  return Number(whole).toLocaleString();
}

export const calculateTraderInfo = (trader: LeaderBoardTrader) => {
  const formattedSize = formatAmount(trader?.size, USD_DECIMALS, 3, true);
  const leverage = (BigInt(trader?.size ?? 0) * BASIS_POINTS_DIVISOR) / BigInt(trader?.collateral ?? 0);
  const formattedLeverage = readableNumber(formatFixed(leverage, 4), false);
  const formattedRealisedPnl = formatAmount(trader?.realisedPnl ?? 0, USD_DECIMALS, 3, true);
  const realisedPnlNum = parseFloat(formattedRealisedPnl.replace(/,/g, ""));
  const lossTradeCount = (trader?.settledTradeCount ?? 0) - (trader?.winTradeCount ?? 0);

  return {
    formattedSize,
    formattedLeverage,
    formattedRealisedPnl,
    realisedPnlNum,
    lossTradeCount,
  };
};
