import React, { useRef, useEffect } from "react";
import { useMedia } from "react-use";
import { RampInstantSDK } from "@ramp-network/ramp-instant-sdk";
import { useChainId } from "lib/chains";
import { useWeb3React } from "@web3-react/core";
import { FANTOM, BSC } from "config/chains";
import SEO from "components/Common/SEO";
import { Trans } from "@lingui/macro";
import { getPageTitle } from "lib/legacy";
import "./BuyCrypto.css";

const BuyCrypto = () => {
  const { chainId } = useChainId();
  const { account } = useWeb3React();
  const isMobile = useMedia("(max-width: 900px)");
  const rampContainerRef = useRef(null);

  useEffect(() => {
    const supportedDefaultAssets = {
      [FANTOM]: "FANTOM_FTM",
      [BSC]: "BSC_BNB",
    };

    const rampInstance = new RampInstantSDK({
      hostAppName: "Lif3",
      hostLogoUrl: "https://assets.lif3.com/wallet/ramp/Lif3.com-dark.svg",
      hostApiKey: "k4jnfagsmjemjbfaxocxsnbtq2g76wfyvkdv42cs",
      variant: isMobile ? "embedded-mobile" : "embedded-desktop",
      containerNode: rampContainerRef.current,
      defaultAsset: supportedDefaultAssets[chainId],
      enabledFlows: ["ONRAMP", "OFFRAMP"],
      defaultFlow: "ONRAMP",
      userAddress: account ?? "",
    });

    if (rampContainerRef.current && chainId) {
      rampInstance.show();
    }

    return () => {
      rampInstance.close();
    };
  }, [rampContainerRef, chainId, account, isMobile]);

  return (
    <SEO title={getPageTitle("Buy Crypto")}>
      <div className="page-layout default-container BuyCrypto">
        <div className="section-title-block">
          <div className="section-title-content">
            <div className="Page-title BuyCrypto-heading">
              <Trans>Buy Crypto</Trans>
            </div>
          </div>
        </div>
        <div className="BuyCrypto-content" ref={rampContainerRef} />
      </div>
    </SEO>
  );
};

export default BuyCrypto;
