import React, { useState, useEffect, useReducer, useMemo } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import Jazzicon from "react-jazzicon";
import { useChainId } from "lib/chains";
import { leaderBoardSupportedNetworks, leaderBoardWebSocketInfo } from "lib/constants";
import { LeaderBoardPositionExtended } from "./types";
import { calculatePositionsInfo } from "./utils";
import { shortenAddress } from "lib/legacy";
import { useInfoTokens } from "domain/tokens";
import "./LeaderBoardPositionsTable.css";
import { LeaderBoardCard } from "./LeaderBoardCard";
import { leaderBoardReducer } from "./reducers";
import { initialState, networkImg, tokenImg } from "./constants";
import { updatePositionsAction } from "./actions";
import { BSC, FANTOM, NETWORK_METADATA } from "config/chains";

export function LeaderBoardPositionsTable() {
  const { chainId } = useChainId();
  const { active, library } = useWeb3React();
  const [state, dispatch] = useReducer(leaderBoardReducer, initialState);
  const [sortBy, setSortBy] = useState<"size" | "realisedPnl">("size");
  const [sortDirection, setSortDirection] = useState<"asc" | "dsc">("asc");
  const { infoTokens: infoTokensFTM } = useInfoTokens(library, FANTOM, active, undefined, undefined);
  const { infoTokens: infoTokensBSC } = useInfoTokens(library, BSC, active, undefined, undefined);
  const combinedInfoTokens = {
    ...infoTokensFTM,
    ...infoTokensBSC,
  };

  useEffect(() => {
    const getLeaderBoardPositionsRequestBody = (chainId: number) => ({
      topic: leaderBoardWebSocketInfo.topics.requestOpenTrades,
      body: {
        offset: 0, // skips N results
        pageSize: 50, // displays N results
        chain: chainId, // chain ID,
        sortBy: sortBy, // Possible values: size, realisedPnl,
        sortDirection: sortDirection, // Possible values: asc, dsc
      },
    });
    const ws = new WebSocket(leaderBoardWebSocketInfo.url);

    ws.onopen = () => {
      leaderBoardSupportedNetworks.forEach((chainId) => {
        ws.send(JSON.stringify(getLeaderBoardPositionsRequestBody(chainId)));
      });
    };
    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.topic === leaderBoardWebSocketInfo.topics.requestOpenTrades) {
        dispatch(updatePositionsAction(response.body.chain, response.body.page));
      }
    };
    ws.onerror = (error) => {
      console.error(error);
    };
    ws.onclose = (event) => {
      console.info(
        `websocket connection was closed with the reason: ${event.reason}, code: ${event.code} and wasClean: ${event.wasClean}`
      );

      ws.close();
    };

    return () => {
      ws.close();
    };
  }, [chainId, sortBy, sortDirection]);

  const handleSizeClick = () => {
    setSortBy("size");
    setSortDirection((prevState) => (prevState === "asc" ? "dsc" : "asc"));
  };
  const handlePnlClick = () => {
    setSortBy("realisedPnl");
    setSortDirection((prevState) => (prevState === "asc" ? "dsc" : "asc"));
  };

  const sortedData = useMemo(
    () =>
      Object.keys(state)
        .reduce<LeaderBoardPositionExtended[]>(
          (acc, cur) => [...acc, ...state[cur].map((position) => ({ ...position, chain: cur }))],
          []
        )
        .sort(
          (a, b) =>
            parseFloat(((sortDirection === "asc" ? b : a)[sortBy] ?? "").replace(/,/g, "")) -
            parseFloat(((sortDirection === "asc" ? a : b)[sortBy] ?? "").replace(/,/g, ""))
        ),
    [state, sortBy, sortDirection]
  );

  return (
    <div className="LeaderBoard-content-box">
      <div className="LeaderBoardPositionsTable">
        <table className="LeaderBoardPositionsTable-table">
          <thead className="LeaderBoardPositionsTable-table-header">
            <tr className="LeaderBoardPositionsTable-header-row">
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Account
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Market
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Side
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Leverage
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button
                  onClick={handleSizeClick}
                  className={`LeaderBoardPositionsTable-header-btn ${sortBy === "size" ? "is-active" : ""}`}
                >
                  Size
                  {sortBy === "size" && sortDirection === "asc" ? (
                    <AiOutlineArrowUp className="LeaderBoardPositionsTable-header-icon" />
                  ) : (
                    <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" />
                  )}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Entry Price
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button
                  onClick={handlePnlClick}
                  className={`LeaderBoardPositionsTable-header-btn ${sortBy === "realisedPnl" ? "is-active" : ""}`}
                >
                  PNL
                  {sortBy === "realisedPnl" && sortDirection === "asc" ? (
                    <AiOutlineArrowUp className="LeaderBoardPositionsTable-header-icon" />
                  ) : (
                    <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" />
                  )}
                </button>
              </td>
              <td className="LeaderBoardPositionsTable-header-cell">
                <button className="LeaderBoardPositionsTable-header-btn">
                  Chain
                  {/* <AiOutlineArrowDown className="LeaderBoardPositionsTable-header-icon" /> */}
                </button>
              </td>
            </tr>
          </thead>
          <tbody className="LeaderBoardPositionsTable-table-body">
            {!!sortedData?.length &&
              sortedData.map((position, index) => {
                const indexTokenAddressCheckSummed = ethers.utils.getAddress(position?.indexToken ?? "");
                const indexTokenInfo = combinedInfoTokens[indexTokenAddressCheckSummed];

                const {
                  formattedSize,
                  formattedLeverage,
                  formattedAveragePrice,
                  formattedRealisedPnl,
                  realisedPnlNum,
                } = calculatePositionsInfo(position);

                return (
                  <tr className="LeaderBoardPositionsTable-row" key={position?.id ?? 0}>
                    <td className="LeaderBoardPositionsTable-cell">
                      <span className="LeaderBoardPositionsTable-cell-row">
                        <span>{index + 1}</span>
                        <div className="LeaderBoardPositionsTable-cell-logo">
                          <Jazzicon diameter={24} seed={parseInt((position?.account ?? "0").slice(2, 10), 16)} />
                        </div>
                        <span className="LeaderBoardPositionsTable-cell-name">
                          {shortenAddress(position?.account, 13)}
                        </span>
                      </span>
                    </td>
                    <td className="LeaderBoardPositionsTable-cell">
                      <div className="LeaderBoardPositionsTable-cell-row">
                        <div className="LeaderBoardPositionsTable-cell-logo">
                          <img
                            src={`https://assets.lif3.com/swap/tokens/${
                              tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol
                            }.svg`}
                            alt={tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol}
                            width="24px"
                          />
                        </div>
                        <span>{tokenImg[indexTokenInfo?.symbol] ?? indexTokenInfo?.symbol}</span>
                      </div>
                    </td>
                    <td
                      className={`LeaderBoardPositionsTable-cell LeaderBoardPositionsTable-cell-price ${
                        position?.isLong ? "positive" : "negative"
                      }`}
                    >
                      {`${position?.isLong ? "Long" : "Short"}`}
                    </td>
                    <td className="LeaderBoardPositionsTable-cell">{`${formattedLeverage}x`}</td>
                    <td className="LeaderBoardPositionsTable-cell">{`$${formattedSize}`}</td>
                    <td className="LeaderBoardPositionsTable-cell">{`$${formattedAveragePrice}`}</td>
                    <td className="LeaderBoardPositionsTable-cell">
                      <span
                        className={`LeaderBoardPositionsTable-cell-price ${
                          realisedPnlNum < 0 ? "negative" : "positive"
                        }`}
                      >
                        {`${realisedPnlNum < 0 ? "" : "+"}${formattedRealisedPnl}`}
                      </span>
                    </td>
                    <td className="LeaderBoardPositionsTable-cell">
                      <div className="LeaderBoardPositionsTable-cell-row">
                        <div className="LeaderBoardPositionsTable-cell-logo">
                          <img
                            src={`https://assets.lif3.com/swap/tokens/${
                              networkImg[position?.chain ?? 0] ?? position?.chain
                            }.svg`}
                            alt={networkImg[position?.chain ?? 0] ?? position?.chain}
                            width="20px"
                          />
                        </div>
                        <span>{NETWORK_METADATA[position?.chain ?? 0]?.chainName}</span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ul className="LeaderBoard-mobile-list">
        {!!sortedData?.length &&
          sortedData.map((position) => (
            <li className="LeaderBoard-mobile-item" key={position?.id}>
              <LeaderBoardCard position={position} combinedInfoTokens={combinedInfoTokens} />
            </li>
          ))}
      </ul>
    </div>
  );
}
