import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { useChainId } from "lib/chains";
import { getContract } from "config/contracts";
import GlpManager from "abis/GlpManager.json";
import ReaderV2 from "abis/ReaderV2.json";
import { contractFetcher } from "lib/contracts";
import { ethers } from "ethers";
import { expandDecimals } from "lib/numbers";
import { GLP_DECIMALS, USD_DECIMALS } from "lib/legacy";

export function useLLPPrice() {
  const { AddressZero } = ethers.constants;
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();
  const glpManagerAddress = getContract(chainId, "GlpManager");
  const readerAddress = getContract(chainId, "Reader");
  const gmxAddress = getContract(chainId, "GMX");
  const glpAddress = getContract(chainId, "GLP");
  const usdgAddress = getContract(chainId, "USDG");
  const tokensForSupplyQuery = [gmxAddress, glpAddress, usdgAddress];

  const { data: aums }: any = useSWR([`Dashboard:getAums:${active}`, chainId, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(library, GlpManager),
  });
  const { data: totalSupplies }: any = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: contractFetcher(library, ReaderV2, [tokensForSupplyQuery]),
    }
  );

  let aum;

  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let glpPrice;
  let glpSupply;
  let glpMarketCap;

  if (aum && totalSupplies && totalSupplies[3]) {
    glpSupply = totalSupplies[3];
    glpPrice =
      aum && aum.gt(0) && glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(glpSupply)
        : expandDecimals(1, USD_DECIMALS);
    glpMarketCap = glpPrice.mul(glpSupply).div(expandDecimals(1, GLP_DECIMALS));
  }

  return { glpPrice };
}
