import { LeaderboardState } from "./types";
import { BSC, FANTOM } from "config/chains";

export const LEADER_BOARD_TIME_INTERVAL_FILTER_OPTIONS = ["86400", "604800", "2628000", "3.154e+8"];
export const LEADER_BOARD_TIME_INTERVAL_FILTER_LABELS = {
  86400: "24 hours",
  604800: "7 days",
  2628000: "1 month",
  "3.154e+8": "All time",
};

export const initialState: LeaderboardState = {
  [FANTOM]: [],
  [BSC]: [],
};

export const TRADERS_ACTIONS = {
  UPDATE_TRADERS_ACTION: "UPDATE_TRADERS_ACTION",
};
