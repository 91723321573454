import React from "react";
import { BiChevronRight } from "react-icons/bi";
import "./styles.css";

type Props = {
  heading: string | React.ReactElement;
  description: string | React.ReactElement;
  btnText?: string;
  url?: string;
};

export const PageHeader = ({ heading, description, url, btnText }: Props) => {
  return (
    <div className="Page-header-box">
      <div className="Page-header-col">
        <h1 className="Page-heading">{heading}</h1>
        <p className="Page-description">{description}</p>
      </div>
      {url && (
        <a className="Page-header-link" href={url} target="_blank" rel="noreferrer">
          {btnText ? btnText : "Learn More"}
          <BiChevronRight className="Page-header-icon" />
        </a>
      )}
    </div>
  );
};
