import { useEffect, useLayoutEffect, useState } from "react";
import { useLocalStorage } from "react-use";
import { useWeb3React } from "@web3-react/core";

import { config } from "../config";
import { candleChartStyleOverrides } from "../overrides/candleChartStyleOverrides";
import { getDefaultOverrides } from "../overrides/defaultOverrides";
import { widget, IChartingLibraryWidget, ResolutionString, IChartWidgetApi } from "../../../charting_library";
import { useChainId } from "lib/chains";
import { omit } from "lib/omit";

import { DataPulseProvider } from "datafeeds/udf/lib/data-pulse-provider";
import { HistoryProvider } from "../helpers/history-provider";

export enum ResolutionValues {
  FIVE_MINUTES = "5",
  FIFTEEN_MINUTES = "15",
  ONE_HOUR = "60",
  FOUR_HOURS = "240",
  ONE_DAY = "1440",
}

export const useChart = (tokenSymbol: string) => {
  const [tvWidget, setTvWidget] = useState<IChartingLibraryWidget | null>(null);
  const [chart, setChart] = useState<IChartWidgetApi | null>(null);
  const { account } = useWeb3React();
  const { chainId } = useChainId();

  const [localStorageResolution, setLocalStorageResolution] = useLocalStorage<ResolutionValues>(
    "tvChartResolution",
    ResolutionValues.ONE_HOUR
  );
  const [isFullChart, toggleFullChart] = useState(true);

  const selectedSymbol = tokenSymbol;

  const setResolution = (resolution: ResolutionValues) => {
    setLocalStorageResolution(resolution);

    if (resolution !== chart?.resolution()) {
      chart?.setResolution(resolution as any, () => null);
    }
  };

  const [isChartLoading, setIsChartLoading] = useState(true);
  const [isChartLoaded, setIsChartLoaded] = useState(false);

  const [savedCharts, setSavedCharts] = useLocalStorage("saved-charts", JSON.stringify({}));

  const onSave = () => {
    tvWidget?.save((state: any) => {
      const chartsState = state.charts.map((chart: any) => omit("chartProperties", chart));

      setSavedCharts(
        JSON.stringify({ ...JSON.parse(savedCharts!), [selectedSymbol]: { ...state, charts: chartsState } })
      );
    });
  };

  useEffect(() => {
    if (!selectedSymbol) return;

    const historyProvider = new HistoryProvider();
    const dataPulseProvider = new DataPulseProvider(historyProvider, 5 * 1000, chainId);

    const tvWidgetInstance = new widget({
      symbol: selectedSymbol,
      load_last_chart: true,
      interval: localStorageResolution as ResolutionString,
      container_id: "tv_chart_container",
      // @ts-ignore-next-line
      datafeed: {
        onReady: (cb) => {
          const config = {
            supported_resolutions: ["5", "15", "60", "240", "D"],
          };

          setTimeout(() => cb(config as any), 0);
        },

        resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
          const symbol_stub = {
            name: symbolName,
            full_name: symbolName,
            description: "",
            type: "crypto",
            session: "24x7",
            timezone: "America/New_York",
            ticker: symbolName,
            minmov: 1,
            pricescale: 1000,
            has_intraday: true,
            intraday_multipliers: ["5", "15", "60", "240", "1440"],
            supported_resolution: ["5", "15", "60", "240", "1440"],
            volume_precision: 8,
            data_status: "streaming",
          };

          setTimeout(function () {
            onSymbolResolvedCallback(symbol_stub as any);
          }, 0);
        },
        subscribeBars: (symbolInfo, resolution, onTick, listenerGuid) => {
          dataPulseProvider.subscribeBars(symbolInfo, resolution, onTick, listenerGuid);
        },
        unsubscribeBars: (listenerGuid) => {
          dataPulseProvider.unsubscribeBars(listenerGuid);
        },
        // @ts-ignore
        getBars: async (
          symbolInfo,
          resolution: ResolutionString,
          rangeStartDate: number,
          rangeEndDate: number,
          onResult,
          onError: ErrorCallback
        ) => {
          historyProvider
            .getBars(symbolInfo, resolution, rangeStartDate, rangeEndDate, chainId)
            .then((result) => {
              onResult(result.bars, result.meta);
            })
            .catch(onError);
        },
      },
      library_path: "/charting_library/",
      locale: "en",
      autosize: true,
      charts_storage_api_version: "1.1",
      theme: true ? "Dark" : "Light",
      loading_screen: {
        backgroundColor: "#0F1526",
        foregroundColor: "#0F1526",
      },
      auto_save_delay: 5,
      timezone: "exchange",
      overrides: {
        ...candleChartStyleOverrides,
        ...getDefaultOverrides(true),
      },
      ...config,
      disabled_features: isFullChart
        ? ["popup_hints", "use_localstorage_for_settings", "header_symbol_search", "timeframes_toolbar"]
        : config.disabled_features,
    });

    setTvWidget(tvWidgetInstance);

    tvWidgetInstance.onChartReady(() => {
      setChart(tvWidgetInstance.chart());

      tvWidgetInstance?.setSymbol(selectedSymbol, localStorageResolution as ResolutionString, () => null);
    });

    return () => {
      if (tvWidgetInstance !== null) {
        tvWidgetInstance.remove();

        setTvWidget(null);
      }
    };
  }, [isFullChart, selectedSymbol, account, localStorageResolution, chainId]);

  useLayoutEffect(() => {
    if (!selectedSymbol) {
      setIsChartLoading(false);
      setIsChartLoaded(true);

      return;
    }

    setIsChartLoading(true);
    setIsChartLoaded(false);

    if (tvWidget) {
      tvWidget.onChartReady(() => {
        setChart(tvWidget.chart());

        tvWidget.subscribe("onAutoSaveNeeded", () => {
          onSave();
        });

        tvWidget.load(JSON.parse(savedCharts!)[selectedSymbol]);

        setIsChartLoading(false);
        setIsChartLoaded(true);
      });
    }
  }, [tvWidget, isFullChart, selectedSymbol, account]);

  return {
    isChartLoading,
    isChartLoaded,
    setResolution,
    localStorageResolution,
    isFullChart,
    toggleFullChart,
  };
};
