import { ethers } from "ethers";
import { BSC, FANTOM } from "./chains";

const { AddressZero } = ethers.constants;

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [FANTOM]: {
    // FANTOM
    Vault: "0x58e3018B9991aBB9075776537f192669D69cA930",
    Router: "0x1C1CCCB2962E65E60F32D583Dd0AB53a78441553",
    VaultReader: "0x873A16508eEE4a269D46874A70a313D98621D92D",
    Reader: "0xd8F126Af4A83d81303278a802E377abAdDAc287D",
    GlpManager: "0xAD34B2ec248ad299fc1DDdD7E1eD5A63A6951C57",
    RewardRouter: "0x37d13Ff55151BE74765B5Afb8257292d311dd98E",
    GlpRewardRouter: "0x37d13Ff55151BE74765B5Afb8257292d311dd98E",
    RewardReader: "0x8F437d76067e30921879F29CE863362920C10678",
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83", //WFTM
    GLP: "0x447bfD0947846Eb1d7C54069D7f7457a54793447",
    GMX: "0xbf60e7414EF09026733c1E7de72E7393888C64DA", //LIF3
    ES_GMX: "0x8E7b421a7d2a6A36e65408f5e94bD5D4AE9fb3c2",
    BN_GMX: "0x764BD361fCb3D0F2e0Eb3fB0B684Fd0eDC4533E9",
    USDG: "0x92A251bcAf765079BCc6Da59cc823EeA72dd2912",
    ES_GMX_IOU: "", // placeholder address

    StakedGmxTracker: "0x1108956b22C309D86c8A93138E5d9e8F96d77452",
    BonusGmxTracker: "0xEd902D953AE92B40350beB594436EF8680205b94",
    FeeGmxTracker: "0x9cDee1Cf9C1feF54366124EaAc44883299AAB66D",
    FeeGlpTracker: "0x6A6a934275D2202E2e7b11FD55f51341217d8733",
    StakedGlpTracker: "0xd25aa55ce4fb48391fa470Fd758b1da01Eb61D47",

    StakedGmxDistributor: "0x60D82beA52439FBf254Ee3eC7A2675517B95C848",
    StakedGlpDistributor: "0xFB062772548B0102aE3AFfb6fEaf9ef495761497",

    GmxVester: "0x35901F4f94d74679454b3E23762a3bceF6E0Bf71",
    GlpVester: "0xfB226E4D617def4BB2522ec86F383f1Ac9518153",

    OrderBook: "0x4238F20D1EC3Ac0dD6fa3Af5dc9b1940D4973Bd8",
    OrderExecutor: "",
    OrderBookReader: "0x521eEe6f4f04b516B77966Bd908Db1Db232bD98B",

    PositionRouter: "0xF68FC0EF48859a3f4380Ca756f7360969ED09376",
    PositionManager: "0xF150BeE00514Ce942d834f1b546478f9a217aC2e",

    TraderJoeGmxAvaxPool: "0xd62cacdb69000fed31bb348e9c0e073bb8ad7caf",
    ReferralStorage: "0x67F5A88Ad8e1A79341489AB2AbCBf433E749C0b3",
    ReferralReader: "0x72Ad413CB61B75D9b42FeE6dA477844798589492",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [BSC]: {
    Vault: "0x089Bd994241db63a5dc5C256481d1722B23EF8d0",
    Router: "0x355230971968FC1C69f06F22a84f89DB75944Bc4",
    VaultReader: "0x63f312E6089AD07680F52fcf14CF502dd1B6337B",
    Reader: "0x8DDDB7Fee60Fe4a65f9Eb58dB33593C222dEcA89",
    GlpManager: "0x5C522523d9d688D4e7D5D2974FA6FC46E3AF5b43",
    RewardRouter: "0x9180bD7dB526c8166e076BB5133fcAc980268AD7", // v2?
    GlpRewardRouter: "0x9180bD7dB526c8166e076BB5133fcAc980268AD7",
    RewardReader: "0x034CDAF0A39Fe945f78aF5d8a916646429d6Fb96",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", // WBNB
    GLP: "0x115B0C8470A873F08B1AFE6C27Ca12c2cB1DeA5c",
    GMX: "0x80D2Fe89b6C6c24edfB553DAF35599649AC55283", // LIF3
    ES_GMX: "0x611430A234f8582958FCb1a55e2a3e4e2f6cB00a",
    BN_GMX: "0x3C06fD98652a83dD3BaFDDFD4e06a80bb813492d",
    USDG: "0xda7c08d53d95737034F32360B3Fdc17D2c77E338",
    ES_GMX_IOU: "", // placeholder address

    StakedGmxTracker: "0x98FeA604B3f78E84bBAE294a609a85E089891caE",
    BonusGmxTracker: "0x56E02c36faE8DC0b4de28d51e634b0cCE58A58d0",
    FeeGmxTracker: "0x017Eb348A3F3d50Fd40233dDBE0CdBBB2232cfbb",
    FeeGlpTracker: "0xF6DeCbc55B6B101d4dC5E701044dfd971C93cF0c",
    StakedGlpTracker: "0xF463B05153be63a7d890a132074ED2F3e87C71EC",

    StakedGmxDistributor: "0x67DF6b22f23286ac17Ff8c985e9b76df6F07c102",
    StakedGlpDistributor: "0xC18e82CF8c6025BcB8E7ee71c4062099FAC02ce0",

    GmxVester: "0xd2403ba56F0C29a4479471190995066FAD0aC02f",
    GlpVester: "0x0E484F95144b78814Fd662Fa1b8E14Ed6B2f5F8E",

    OrderBook: "0x12FeCEAeDd63ebBAFEc49bD8e40302a7a940d852",
    OrderExecutor: "",
    OrderBookReader: "0x351C27bFec82a8172E78C8dF6486e1De32Bb778E",

    PositionRouter: "0x68C1Ebec6223a5623d64FDC3BF70eB69b3Ba816E",
    PositionManager: "0x42e92b0D27348296B8d03dc86635Bf7526d73e66",

    TraderJoeGmxAvaxPool: "0x453f1d91557da5e87e746d9dc3afe9631057658d",
    ReferralStorage: "0x34903deEbCb7C7C745710aea7370f9F28A994231",
    ReferralReader: "0x5b8F1E63E273d8B2eDdb279F1E866DFbED1a1F23",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
