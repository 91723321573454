import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { BiChevronDown } from "react-icons/bi";
import { useChainId } from "lib/chains";

export const BuyCryptoLink = ({}) => {
  const { chainId } = useChainId();
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId]);

  return (
    <div className="Buy-Crypto-Link-Box">
      <div className="Buy-Crypto-Link-Col">
        <span className="Buy-Crypto-Link-Heading">I don&apos;t have crypto</span>
        <span className="Buy-Crypto-Link-Text">
          We've got you covered! You can use your credit or debit card to get crypto.
        </span>
      </div>
      <Link
        className="Buy-Crypto-Link-Action"
        to={{ pathname: "/buy-crypto", search: chainIdOnlysearchParams.toString() }}
      >
        Buy Crypto <BiChevronDown className="Buy-Crypto-Link-Icon" />
      </Link>
    </div>
  );
};
