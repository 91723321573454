import { POSITIONS_ACTIONS } from "./constants";
import { ActionType, LeaderBoardPositionFull } from "./types";

export const updatePositionsAction = (chainId: number, data: LeaderBoardPositionFull[]): ActionType => ({
  type: POSITIONS_ACTIONS.UPDATE_POSITIONS_ACTION,
  payload: {
    chainId,
    data,
  },
});
