import { TRADERS_ACTIONS } from "./constants";
import { ActionType, LeaderBoardTrader } from "./types";

export const updateTradersAction = (chainId: number, data: LeaderBoardTrader[]): ActionType => ({
  type: TRADERS_ACTIONS.UPDATE_TRADERS_ACTION,
  payload: {
    chainId,
    data,
  },
});
