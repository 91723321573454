import React from "react";
import "./Footer.css";
import { SOCIAL_LINKS } from "./constants";
import dayjs from "dayjs";
import PoweredByLogo from "img/powered-by.svg";

type Props = { showRedirectModal?: (to: string) => void; redirectPopupTimestamp?: () => void };

export function Footer({ showRedirectModal, redirectPopupTimestamp }: Props) {
  return (
    <div className="Footer-Wrapper">
      <div className="Footer-Wrapper-Inner">
        <div className="Footer-Links-Wrapper">
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">CoinGecko</span>
            <a className="Footer-Link" href="https://www.coingecko.com/en/coins/lif3" target="_blank" rel="noreferrer">
              LIF3
            </a>
            <a
              className="Footer-Link"
              href="https://www.coingecko.com/en/coins/lif3-lshare"
              target="_blank"
              rel="noreferrer"
            >
              LSHARE
            </a>
            <a className="Footer-Link" href="https://www.coingecko.com/en/coins/l3usd" target="_blank" rel="noreferrer">
              L3USD
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">LIF3.com</span>
            <a className="Footer-Link" href="https://lif3.com/?chainId=56" target="_blank" rel="noreferrer">
              on BNB Chain
            </a>
            <a className="Footer-Link" href="https://lif3.com/?chainId=250" target="_blank" rel="noreferrer">
              on Fantom
            </a>
            <a className="Footer-Link" href="https://lif3.com/?chainId=137" target="_blank" rel="noreferrer">
              on Polygon
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Buy</span>
            <a className="Footer-Link" href="https://lif3.com/swap" target="_blank" rel="noreferrer">
              Buy $LIF3
            </a>
            <a className="Footer-Link" href="/liquidity" rel="noreferrer">
              Buy $LLP
            </a>
            <a className="Footer-Link" href="https://lif3.com/buy-crypto" target="_blank" rel="noreferrer">
              Buy/Sell Crypto
            </a>
          </div>
          <div className="Footer-Links-Column">
            <span className="Footer-Links-Label">Lif3 Ecosystem</span>
            <a className="Footer-Link" href="https://lif3.com/" target="_blank" rel="noreferrer">
              Lif3.com
            </a>
            <a className="Footer-Link" href="https://felix.com/" target="_blank" rel="noreferrer">
              Felix.com
            </a>
            <a className="Footer-Link" href="https://docs.lif3.com/" target="_blank" rel="noreferrer">
              Docs
            </a>
          </div>
        </div>
        <div className="Footer-Socials-Wrapper">
          {SOCIAL_LINKS.map((platform) => {
            return (
              <a
                className="Footer-Social-Item"
                key={platform.name}
                href={platform.link}
                target="_blank"
                rel="noreferrer"
              >
                <img src={platform.icon} alt={platform.name} />
              </a>
            );
          })}
        </div>
        <div className="Footer-Text-Description">
          <p>Lif3.com is a complete multi-chain DeFi Ecosystem.</p>
        </div>
        {/* <div className="Copyright-Row">
          <div className="Footer-Copyright-Text">Copyright © Lif3 {dayjs().format("YYYY")}</div>
          <a className="Footer-Audit-Wrapper" href="https://fantom.foundation/" target="_blank" rel="noreferrer">
            <span className="Footer-Audit-Text">Powered by</span>
            <img src={PoweredByLogo} className="Footer-Audit-Icon" alt="Audited By" />
            <span className="Footer-Audit-Text">Fantom</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}
