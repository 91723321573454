import { LeaderboardState } from "./types";
import { BSC, FANTOM } from "config/chains";

export const initialState: LeaderboardState = {
  [FANTOM]: [],
  [BSC]: [],
};

export const POSITIONS_ACTIONS = {
  UPDATE_POSITIONS_ACTION: "UPDATE_POSITIONS_ACTION",
};

export const networkImg = {
  [FANTOM]: "FTM",
  [BSC]: "BNB",
};

export const tokenImg = {
  WFTM: "FTM",
  WBNB: "BNB",
};
