import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";

import lif3Icon from "img/ic_lif3_40.svg";
import llpIcon from "img/ic_llp_40.svg";

import { isHomeSite } from "lib/legacy";

import APRLabel from "../APRLabel/APRLabel";
import { HeaderLink } from "../Header/HeaderLink";
import { BSC, FANTOM } from "config/chains";

import ExternalLink from "components/ExternalLink/ExternalLink";
import AssetDropdown from "pages/Dashboard/AssetDropdown";
import AddTokenBtn from "components/AddTokenBtn/AddTokenBtn";
import { useChainId } from "lib/chains";

export default function TokenCard({ showRedirectModal, redirectPopupTimestamp }) {
  const { chainId } = useChainId();
  const chainIdOnlysearchParams = useMemo(() => new URLSearchParams(`chainId=${chainId}`), [chainId]);
  const isHome = isHomeSite();
  // const { chainId } = useChainId();
  // const { active } = useWeb3React();

  // const changeNetwork = useCallback(
  //   (network) => {
  //     if (network === chainId) {
  //       return;
  //     }
  //     if (!active) {
  //       setTimeout(() => {
  //         return switchNetwork(network, active);
  //       }, 500);
  //     } else {
  //       return switchNetwork(network, active);
  //     }
  //   },
  //   [chainId, active]
  // );

  const BuyLink = ({ className, to, children, network }) => {
    if (isHome && showRedirectModal) {
      return (
        <HeaderLink
          to={to}
          className={className}
          redirectPopupTimestamp={redirectPopupTimestamp}
          showRedirectModal={showRedirectModal}
        >
          {children}
        </HeaderLink>
      );
    }

    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  };

  return (
    <div className="Home-token-card-options">
      <div className="Home-token-card-option App-card">
        <div className="Home-token-card-header">
          <div className="Home-token-card-option-icon">
            <img src={lif3Icon} alt="gmxBigIcon" />
            <Trans>LIF3</Trans>
            <div>
              <AssetDropdown assetSymbol="GMX" />
            </div>
          </div>
          <AddTokenBtn assetSymbol="GMX" btnText="Add LIF3 to Wallet" />
        </div>

        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>LIF3 is the utility token. Accrues a percentage of the platform's generated fees.</Trans>
          </div>
          <div className="Home-token-card-option-list">
            <div className="Home-token-card-option-apr">
              <Trans>Fantom APR:</Trans> <APRLabel chainId={FANTOM} label="gmxAprTotal" />{" "}
            </div>
            <div className="Home-token-card-option-apr">
              <Trans>BNB Chain APR:</Trans> <APRLabel chainId={BSC} label="gmxAprTotal" />{" "}
            </div>
          </div>
          <div className="Home-token-card-option-action">
            <ExternalLink href="https://lif3.com/swap" className="default-btn">
              <Trans>Buy LIF3</Trans>
            </ExternalLink>
            <ExternalLink href="https://docs.lif3.com/lif3-trade/tokenomics" className="default-btn read-more">
              <Trans>Read more</Trans>
            </ExternalLink>
          </div>
        </div>
      </div>
      <div className="Home-token-card-option App-card">
        <div className="Home-token-card-header">
          <div className="Home-token-card-option-icon">
            <img src={llpIcon} alt="glpBigIcon" />
            <Trans>LLP</Trans>
            <div>
              <AssetDropdown assetSymbol="GLP" />
            </div>
          </div>
          <AddTokenBtn assetSymbol="GLP" btnText="Add LLP to Wallet" />
        </div>
        <div className="Home-token-card-option-info">
          <div className="Home-token-card-option-title">
            <Trans>LLP is the liquidity provider token. Accrues a percentage of the platform's generated fees.</Trans>
          </div>
          <div className="Home-token-card-option-list">
            <div className="Home-token-card-option-apr">
              <Trans>Fantom APR:</Trans> <APRLabel chainId={FANTOM} label="glpAprTotal" key="FANTOM" />{" "}
            </div>
            <div className="Home-token-card-option-apr">
              <Trans>BNB Chain APR:</Trans> <APRLabel chainId={BSC} label="glpAprTotal" key="BSC" />{" "}
            </div>
          </div>
          <div className="Home-token-card-option-action">
            <BuyLink
              to={{
                pathname: "/liquidity",
                search: chainIdOnlysearchParams.toString(),
              }}
              className="default-btn"
              network={FANTOM}
            >
              <Trans>Buy LLP</Trans>
            </BuyLink>
            <a
              href="https://docs.lif3.com/lif3-trade/llp"
              target="_blank"
              rel="noreferrer"
              className="default-btn read-more"
            >
              <Trans>Read more</Trans>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
