import React from "react";
import { ResolutionValues, useChart } from "./hooks/useTvChart";

type Props = {
  tokenSymbol: string;
};

export const TvChart: React.FC<Props> = (props) => {
  const { isChartLoading, isChartLoaded, localStorageResolution, setResolution } = useChart(props.tokenSymbol);

  return (
    <div style={{ height: "100%" }}>
      <div className="ExchangeChart" id="tv_chart_container" />
      {/* <div>
        <button onClick={() => setResolution(ResolutionValues.FIVE_MINUTES)}>5m</button>
        <button onClick={() => setResolution(ResolutionValues.FIFTEEN_MINUTES)}>15m</button>
        <button onClick={() => setResolution(ResolutionValues.ONE_HOUR)}>1h</button>
        <button onClick={() => setResolution(ResolutionValues.FOUR_HOURS)}>4h</button>
        <button onClick={() => setResolution(ResolutionValues.ONE_DAY)}>1d</button>
      </div> */}
    </div>
  );
};
