import React, { CSSProperties } from "react";
import { USD_DECIMALS } from "lib/legacy";
import { formatKeyAmount } from "lib/numbers";
import { addTokenToMetamask } from "lib/wallets";
import { useCarouselTokens } from "./useCarouselTokens";
import "./TokenCarousel.css";

export const TokenCarousel = () => {
  const { carouselTokensInfo, chainId } = useCarouselTokens();
  const firstToken = carouselTokensInfo[0];

  const addAllTokensToWallet = async () => {
    try {
      await Promise.all([
        ...carouselTokensInfo.map((token) =>
          addTokenToMetamask({
            address: token?.address ?? "",
            decimals: token?.decimals ?? 18,
            imageUrl: token?.imageUrl ?? "",
            symbol: token?.symbol ?? "",
          })
        ),
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="PricesCarousel-container"
      style={
        {
          "--no-of-slides": `${carouselTokensInfo?.length + 1 ?? 0}`,
        } as CSSProperties
      }
    >
      <div className="PricesCarousel">
        <div className="PricesCarousel-track" style={{ animationName: `slide${chainId}` } as CSSProperties}>
          {carouselTokensInfo.map((token) => (
            <div className="PricesSlide" key={token?.address}>
              <div className="PriceWrapper" onClick={addAllTokensToWallet}>
                <div className="TokenLogoWrapper">
                  <img src={token?.imageUrl} alt={token?.symbol} width="20px" />
                </div>
                <span>${formatKeyAmount(token, "minPrice", USD_DECIMALS, 2, true)}</span>
              </div>
            </div>
          ))}

          {/* repeat first slide to fake infinite */}

          <div className="PricesSlide">
            <div className="PriceWrapper">
              <div className="TokenLogoWrapper">
                <img src={firstToken?.imageUrl} alt={firstToken?.symbol} width="20px" />
              </div>
              <span>${formatKeyAmount(firstToken, "minPrice", USD_DECIMALS, 2, true)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
