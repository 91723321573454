import { BSC, FANTOM } from "config/chains";

export type CarouselTokenSymbols = "LIF3" | "LLP" | "BTC" | "ETH" | "FTM" | "BOO" | "BNB";

export const carouselTokenSymbols: { [key: number]: CarouselTokenSymbols[] } = {
  [FANTOM]: ["LIF3", "LLP", "BTC", "ETH", "FTM", "BOO"],
  [BSC]: ["LIF3", "LLP", "BTC", "ETH", "BNB"],
};

export type CarouselTokensSortOrder = {
  [key in CarouselTokenSymbols]?: number;
};

export const carouselTokensSortOrder: { [key: number]: CarouselTokensSortOrder } = {
  [FANTOM]: {
    LIF3: 0,
    LLP: 1,
    BTC: 2,
    ETH: 3,
    FTM: 4,
    BOO: 5,
  },
  [BSC]: {
    LIF3: 0,
    LLP: 1,
    BTC: 2,
    ETH: 3,
    BNB: 4,
  },
};
